import React from 'react';
import { IconButton, useTheme } from '@zydalabs/storefront-components';
import { Typography, Button, Box } from '@zydalabs/zac-react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useResponsive } from 'common/hooks';

import { NextImage, PoweredByZyda } from 'common/components';
import { useLayout, useOrderfastLinks, useUserInfo } from 'service/hooks';
import { LANGUAGE_AR, LANGUAGE_EN, URLS } from 'common/constants';
import { changeRoute, replaceRoute } from 'common/utils';
import ServiceConfigs from 'service/config';
import {
  StyledFixedWrapper,
  StyledLogoBlock,
  StyledLinksWrapper,
  StyledOrderFastPageWrapper,
  StyledLangButton,
  StyledOrderfastLandLoading,
  StyledPageWrapper,
  StyleOrderButtonWrapper,
} from './styles';
import StoreBrandInfoLoading from './OrderfastLandLoading';

const OrderfastLand = () => {
  const router = useRouter();
  const { t } = useTranslation('land');
  const theme = useTheme();
  const isLargeScreen = useResponsive(`min-width: ${theme.screenBreakpoints.extraLarge}`);
  const { data: layoutSettings, isLoading: isLayoutLoading } = useLayout();
  const { data: userInfo, isLoading: isUserInfoLoading } = useUserInfo();
  const { data: orderfastLinks, isLoading: isOrderfastLinksLoading } = useOrderfastLinks();

  const isLoggedIn = !!ServiceConfigs?.getUserToken();
  const isArabic = router.locale === LANGUAGE_AR;
  const { logoUrl, titleAr, titleEn, descriptionAr, descriptionEn } = layoutSettings || {};

  if (isLayoutLoading || isUserInfoLoading || isOrderfastLinksLoading) {
    return (
      <StyledPageWrapper>
        <StyledOrderfastLandLoading>
          <StoreBrandInfoLoading />
        </StyledOrderfastLandLoading>
      </StyledPageWrapper>
    );
  }
  const actionLink = orderfastLinks[0];
  const links = actionLink && actionLink?.titleEn === 'Tap here to order' ? orderfastLinks.slice(1) : orderfastLinks;

  return (
    <StyledPageWrapper>
      <StyledLangButton isArabic={isArabic}>
        <IconButton
          icon={
            isArabic
              ? () => 'EN'
              : () => (
                  <Typography variant="heading16" m={0} cursor="pointer">
                    ع
                  </Typography>
                )
          }
          withBackground
          onClick={() => {
            const toggledLanguage = isArabic ? LANGUAGE_EN : LANGUAGE_AR;
            const { subdomain, ...queryWithoutSubdomain } = router.query;
            replaceRoute(
              { pathname: router.route, query: queryWithoutSubdomain },
              {
                locale: toggledLanguage,
              },
            );
          }}
        />
      </StyledLangButton>

      <StyledOrderFastPageWrapper>
        <StyledLogoBlock>
          <NextImage src={logoUrl} width={100} height={100} data-testid="store_logo" />
        </StyledLogoBlock>
        <Typography variant={isLargeScreen ? 'heading32' : 'heading24'} mt={4} testId={`${layoutSettings?.id}_store_name`}>
          {isArabic ? titleAr : titleEn}
        </Typography>
        <Typography variant="body14" testId={`${layoutSettings?.id}_store_slogan`}>
          {isArabic ? descriptionAr : descriptionEn}
        </Typography>
        <StyledLinksWrapper>
          {links?.map(link => (
            <Box mb={16} key={link.position}>
              <Button
                variant="tertiary"
                text={isArabic ? link.titleAr : link.titleEn}
                isFluid
                onClick={e => {
                  e.preventDefault();
                  window.open(link.content, '_blank');
                }}
              />
            </Box>
          ))}
        </StyledLinksWrapper>
      </StyledOrderFastPageWrapper>

      <StyledFixedWrapper>
        {isLoggedIn && <Typography mb={4}>{t('welcomeBack', { name: userInfo?.name })}</Typography>}
        <StyleOrderButtonWrapper>
          <Button
            variant="primary"
            isFluid
            text={t('tapToOrder')}
            onClick={e => {
              e.preventDefault();
              changeRoute(URLS.HOME);
            }}
          />
        </StyleOrderButtonWrapper>
        <PoweredByZyda />
      </StyledFixedWrapper>
    </StyledPageWrapper>
  );
};

export default OrderfastLand;
