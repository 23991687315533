import { orderfastLinks } from 'service/queries';
import { OrderfastLink } from 'service/types/generated';
import { useQuery } from 'service/utils';
import { QueryHookResponse } from 'service/types/internal';
import ServiceConfigs from '../../config';

type UseOrderfastLinksType = ({
  initialData,
}?: {
  initialData?: Array<OrderfastLink>;
}) => QueryHookResponse<Array<OrderfastLink>>;

const useOrderfastLinks: UseOrderfastLinksType = ({ initialData } = {}) =>
  useQuery<Array<OrderfastLink>>({
    query: orderfastLinks,
    variables: ServiceConfigs.getSubDomain() ? { subdomain: ServiceConfigs.getSubDomain() } : null,
    handler: (res: any) => res?.orderfastLinks?.sort((a, b) => a.position - b.position),
    config: { initialData },
  });

export default useOrderfastLinks;
