import { gql } from 'graphql-request';

const orderfastLinks = gql`
  query ($subdomain: String!) {
    orderfastLinks(subdomain: $subdomain) {
      id
      position
      titleAr
      titleEn
      content
    }
  }
`;

export default orderfastLinks;
