import React from 'react';
import { ShimmerComponent } from '@zydalabs/storefront-components';

const OrderfastLandLoading = () => (
  <>
    <ShimmerComponent.ShimmerEffect>
      <ShimmerComponent.NoAnimationBox width="100%" alignItems="center" flexDirection="column" mt={48} mb={0}>
        <ShimmerComponent.Circle radius="100" mb={4} />
        <ShimmerComponent.NoAnimationBox flexDirection="column">
          <ShimmerComponent.Rect height={48} mb={4} />
          <ShimmerComponent.Rect height={20} mb={4} />
        </ShimmerComponent.NoAnimationBox>
      </ShimmerComponent.NoAnimationBox>
    </ShimmerComponent.ShimmerEffect>
    {Array.from([...Array(5)].keys()).map(num => (
      <ShimmerComponent.ShimmerEffect key={num}>
        <ShimmerComponent.NoAnimationBox width="100%">
          <ShimmerComponent.Rect mt={3} mb={3} height={48} width="100%" mx={8} borderRadius={1} />
        </ShimmerComponent.NoAnimationBox>
      </ShimmerComponent.ShimmerEffect>
    ))}
  </>
);

export default OrderfastLandLoading;
